export default {
  white: "#fff",
  black: "#000",
  glanceRed: "#fd104d",
  glanceRed2: "#ff0049",
  torchRed: "#fc024d",
  nobel: "#b4b3b3",
  heather: "#bcc3d3",
  mineShaft: "#3f3f3f",
  doveGray: "#6a6a6a",
  silver: "#c1c1c1",
  redRibbon: "#f91151",
  wildSand: "#f4f4f4",
  darkGray: "#171717",
  blue: "#ADD8E6",
  darkPink: "#ff2664"
};
