import React from "react";
import Lottie from "lottie-react";
import ErrorAnimation from "../../../../static/lottie/error.json";
import { css } from "emotion";

const SuccessIcon = ({ width, minHeight, margin }) => {
  return (
    <Lottie
      animationData={ErrorAnimation}
      className={css`
        width: ${width};
        min-height: ${minHeight};
        margin: ${margin};
      `}
    />
  );
};

export default SuccessIcon;
