export default {
  fontTiny: "10px",
  fontSmall: "12px",
  fontBase: "14px",
  fontMedium: "16px",
  fontLarge: "18px",
  fontExtraLarge: "21px",

  fontWtLight: "300",
  fontWtNormal: "400",
  fontWtMedium: "500",
  fontWtSemiBold: "600",
  fontWtBold: "700",
  fontWtExtraBold: "800",
  fontWtBlack: "900"
};
