import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import colors from "../../../common/colors";
import font from "../../../common/font";

const Container = styled.div`
  color: ${colors.white};
  font-weight: ${font.fontWtLight};
  padding: 0 24px;
`;

const SamsungTime = styled.div`
  font-size: ${parseInt(font.fontLarge) * 2}px;
  letter-spacing: -0.38px;
`;

const SamsungDateContainer = styled.div`
  font-size: ${parseInt(font.fontTiny) * 2 + 2}px;
  line-height: 1.45;
`;

const VivoTimeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const VivoTime = styled.div`
  font-size: ${parseInt(font.fontBase) * 3 + 4}px;
  font-weight: ${font.fontWtSemiBold};
  line-height: 1.17;
  flex-grow: 1;
`;

const VivoDateContainer = styled.div`
  color: ${colors.white};
  font-size: ${font.fontLarge};
  font-weight: ${font.fontWtSemiBold};
  line-height: 1.45;
`;

const GalleryIcon = styled.i`
  background: url("/static/images/buzzstorm/vivo-gallery-icon.svg");
  width: 34px;
  height: 34px;
  display: inline-block;
`;

const MiTime = styled.div`
  color: ${colors.wildSand};
  font-size: ${parseInt(font.fontLarge) * 4 - 2}px;
  text-align: center;
`;

const MiDateContainer = styled.div`
  color: ${colors.wildSand};
  font-size: ${font.fontLarge};
  line-height: 1.45;
  text-align: center;
`;

const GenericTime = styled.div`
  color: ${colors.wildSand};
  font-size: ${parseInt(font.fontMedium) * 3 + 5}px;
  text-align: center;
  line-height: 0.96;
`;

const GenericDateContainer = styled.div`
  color: ${colors.wildSand};
  font-size: ${parseInt(font.fontMedium) - 1}px;
  line-height: 3.33;
  text-align: center;
`;

const Clock = ({ isSamsung, isVivo, isMi, isGeneric }) => {
  const [time, setTime] = useState();
  const [date, setDate] = useState();
  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    setTime(
      `${hours < 10 ? `${hours}` : hours}:${
        minutes < 10 ? `0${minutes}` : minutes
      }`
    );
    const options = { weekday: "short", month: "long", day: "numeric" };
    if (isMi || isGeneric) {
      options.month = "short";
    }
    setDate(now.toLocaleDateString("en-GB", options));
  }, [isGeneric, isMi]);
  if (time && date) {
    if (isSamsung) {
      return (
        <Container>
          <SamsungTime>{time}</SamsungTime>
          <SamsungDateContainer>{date}</SamsungDateContainer>
        </Container>
      );
    }
    if (isVivo) {
      return (
        <Container>
          <VivoTimeContainer>
            <VivoTime>{time}</VivoTime>
            <GalleryIcon />
          </VivoTimeContainer>
          <VivoDateContainer>{date}</VivoDateContainer>
        </Container>
      );
    }
    if (isMi) {
      return (
        <Container>
          <MiTime>{time}</MiTime>
          <MiDateContainer>{date}</MiDateContainer>
        </Container>
      );
    }
    if (isGeneric) {
      return (
        <Container>
          <GenericTime>{time}</GenericTime>
          <GenericDateContainer>{date}</GenericDateContainer>
        </Container>
      );
    }
  }
  return null;
};

export default Clock;
