import { css } from "@emotion/core";

export const getUrbanist = () => {
  const styles = css`
    @font-face {
      font-family: "Urbanist";
      src: url("https://web.glance-cdn.com/egc_pwa/ui/fonts/urbanist/Urbanist-ExtraBold.woff2")
          format("woff2"),
        url("https://web.glance-cdn.com/egc_pwa/ui/fonts/urbanist/Urbanist-ExtraBold.woff")
          format("woff");
      font-weight: 800;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: "Urbanist";
      src: url("https://web.glance-cdn.com/egc_pwa/ui/fonts/urbanist/Urbanist-Black.woff2")
          format("woff2"),
        url("https://web.glance-cdn.com/egc_pwa/ui/fonts/urbanist/Urbanist-Black.woff")
          format("woff");
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: "Urbanist";
      src: url("https://web.glance-cdn.com/egc_pwa/ui/fonts/urbanist/Urbanist-Bold.woff2")
          format("woff2"),
        url("https://web.glance-cdn.com/egc_pwa/ui/fonts/urbanist/Urbanist-Bold.woff")
          format("woff");
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: "Urbanist";
      src: url("https://web.glance-cdn.com/egc_pwa/ui/fonts/urbanist/Urbanist-SemiBold.woff2")
          format("woff2"),
        url("https://web.glance-cdn.com/egc_pwa/ui/fonts/urbanist/Urbanist-SemiBold.woff")
          format("woff");
      font-weight: 600;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: "Urbanist";
      src: url("https://web.glance-cdn.com/egc_pwa/ui/fonts/urbanist/Urbanist-Regular.woff2")
          format("woff2"),
        url("https://web.glance-cdn.com/egc_pwa/ui/fonts/urbanist/Urbanist-Regular.woff")
          format("woff");
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: "Urbanist";
      src: url("https://web.glance-cdn.com/egc_pwa/ui/fonts/urbanist/Urbanist-Light.woff2")
          format("woff2"),
        url("https://web.glance-cdn.com/egc_pwa/ui/fonts/urbanist/Urbanist-Light.woff")
          format("woff");
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: "Urbanist";
      src: url("https://web.glance-cdn.com/egc_pwa/ui/fonts/urbanist/Urbanist-SemiBold.woff2")
          format("woff2"),
        url("https://web.glance-cdn.com/egc_pwa/ui/fonts/urbanist/Urbanist-SemiBold.woff")
          format("woff");
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    html,
    body,
    button {
      font-family: "Urbanist", "Roboto", sans-serif;
    }
  `;
  return styles.styles;
};
